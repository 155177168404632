import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Button from 'components/button';
import Twitter from '../../assets/twitter-circle.inline.svg';

const Contact = () => (
  <Layout>
    <SEO
      title="Contact us"
      description="Contact Maistro for a demonstration of our self-serve and managed Tail Spend Management platform"
    />
    <div className="container pb-24">
      <div className="flex flex-col-reverse md:flex-row items-stretch mb-24">
        <div className="md:w-1/2 md:pr-16 flex flex-col">
          <div className="text-primary flex-1 mb-16">
            <h1 className="mb-6">Speak with a specialist</h1>
            <p className="">
              Contact one of our advisors to explore how we can help
              you.
            </p>
            <h5 className="mt-8">Address</h5>
            <p className="mb-8 text-primary--1">
              Rowan House North, 1 The Professional Quarter, Shrewsbury
              Business Park, Shrewsbury, United Kingdom, SY2 6LG
            </p>
            <a href="mailto:enquiries@maistro.com?subject=Website Query">
              <Button
                text="Email Us"
                bg="primary"
                rightIcon="/images/chevronRightIcon.svg"
              />
            </a>
          </div>
          <div>
            <img src="/images/Asset_29@2x.png" alt="" className="w-full" />
          </div>
        </div>
        <div className="md:w-1/2">
          <img src="/images/contact.jpeg" alt="" className="w-full mb-6" />
        </div>
      </div>

      <div className="flex items-start">
        <div className="w-24 border-primary--3 border-r-4 pr-6">
          <Twitter className="" />
        </div>
        <div className="text-primary--1 pl-6 flex-1">
          Procuring services sparks a chain reaction between different
          departments. The biggest challenge for businesses is to ensure that
          the process journey runs smoothly and compliantly. So where to start?
          #ProcuretoPay #SourcetoPay #StrategicProcurement
        </div>
      </div>
    </div>
  </Layout>
);

export default Contact;
